import styled from "styled-components";
import { Radio, Button } from "antd";
import axios from "axios";
import { useState, useRef } from "react";
import NotificationList from "./notificationlist";
import { Input, Textarea, Divider } from "@mantine/core";


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
  flex-direction: column;
  overflow: auto;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  background-color: #fff;
  gap: 20px;
  padding: 30px 15px;
  border-radius: 9px;
  > :last-child {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;
const TopTitle = styled.div`
  width: 70%;
  padding-top: 30px;
  padding-bottom: 20px;
  color: black;
  font-size: 27px;
`;
const StyledButton = styled(Button)`
  width: max-content;
  background-color: #9e0c48;
  border: none;
  border-radius: 5px;
  :hover {
    background-color: #9e0c48;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 1px, rgb(51, 51, 51) 0px 0px 0px 1px;
  }
`;
function Notifications() {
  const [buttonDisable, setbuttonDisable] = useState(false);
  const title = useRef(null);
  const body = useRef(null);
  const sendMessage = () => {
    axios
      .post("notification/send", {
        title: title.current.value,
        body: body.current.value,
      })
      .then(function (response) {
          console.log(response,'gitti')
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Container>
      <TopTitle>
        <h5>
          <strong>New Notification</strong>
        </h5>
      </TopTitle>
      <Form>
        <Input.Wrapper label="Bildirim Konusu" required>
          <Input placeholder="İyi bir etkileşim için maksimum 5 kelime :)" ref={title}/>
        </Input.Wrapper>
        <Textarea
          ref={body}
          Rows={4}
          label="Bildirim metni"
          placeholder="140 karakter"
          required
        />
        <Divider my="sm" />
        <div>
          <StyledButton
            disabled={buttonDisable}
            id="sendbutton"
            type="primary"
            onClick={() => {
              sendMessage();
            }}
          >
            Send Notification
          </StyledButton>

        </div>
      </Form>
      <NotificationList />
    </Container>
  );
}

export default Notifications;
