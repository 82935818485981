import React from "react";
import NotFound from "../../assets/nodata.svg";
import styled from "styled-components";

const DataNotFound = ({position,top,left}) => {
    return (
        <ContainerWrap
            position={position}
            top={top}
            left={left}
            id={"data-not-found"}
        >
            <DataImg></DataImg>
            <DataText>Data Not Found</DataText>
        </ContainerWrap>
    );
};


const ContainerWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 327px);
  position: ${({position}) => position};
  top: ${({top})  => top};
  left: ${({left})  => left};
  transform: translate(-50%, -50%);
`;

const DataText = styled.div`
  font-size: 18px;
  font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif ;
  padding-top: 10px;
  color: #c4cdd3;
`;

const DataImg = styled.div`
  background: url(${NotFound}) no-repeat center center;
  width: 80px;
  height: 80px;
  background-size: 80px;
`;


export default DataNotFound;