import React, { useEffect, useState, Fragment } from "react";
import { Row, Modal, Avatar, Descriptions } from "antd";
import { Text, Progress, Card } from "@mantine/core";
import styled from "styled-components";
import moment from "moment";

const H6 = styled.h6`
  margin-bottom: 10px;
  font-weight: normal;
`;

const axios = require("axios").default;
const Detail = (props) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [studentDetail, setStudentDetail] = useState("cccc");

  const handleOk = () => {
    setIsModalVisible(false);
    props.visibleCallback(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.visibleCallback(null);
  };

  const getUserDetail = () => {
    axios
      .get(`student/detail/${props.student.user_no}`)
      .then(function (response) {
        setStudentDetail(response.data.data);
        return response;
      })
      .catch(function (error) {
        console.log(error)
      });
  };

  useEffect(() => {
    if (props.student.length !== 0) {
      getUserDetail();
      setIsModalVisible(true);
    }
  }, []);

  return (
    <>
      <Modal
        title={studentDetail.first_name + " " + studentDetail.last_name}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Row>
          <Avatar
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "20px",
            }}
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            src={studentDetail.image_url + "/" + studentDetail.image}
          />
        </Row>

        <Descriptions bordered>
          <Descriptions.Item label="Is Active" span={3}>
            {studentDetail.status}
          </Descriptions.Item>
          <Descriptions.Item label="Register Date" span={3}>
            {moment(studentDetail.created_at).format("DD-MM-YYYY h:mm")}
          </Descriptions.Item>
          <Descriptions.Item label="Name Last Name" span={3}>
            {studentDetail.first_name} {studentDetail.last_name}
          </Descriptions.Item>
          <Descriptions.Item label="E-Mail" span={3}>
            {studentDetail.email}
          </Descriptions.Item>
          <Descriptions.Item label="Country" span={3}>
            {studentDetail.country}
          </Descriptions.Item>
          <Descriptions.Item label="City" span={3}>
            {studentDetail.city}
          </Descriptions.Item>
          <Descriptions.Item label="Money" span={3}>
            {studentDetail.money}
          </Descriptions.Item>
          <Descriptions.Item label="TimeZone" span={3}>
            {studentDetail.timezone}
          </Descriptions.Item>
          <Descriptions.Item label="Login Type" span={3}>
            {studentDetail.login_type}
          </Descriptions.Item>
          <Descriptions.Item label="Login Platform" span={3}>
            {studentDetail.login_platform}
          </Descriptions.Item>
          <Descriptions.Item label="Social Type" span={3}>
            {studentDetail.social_type}
          </Descriptions.Item>
          <Descriptions.Item label="Note" span={3}>
            {studentDetail.note}
          </Descriptions.Item>
          <Descriptions.Item label="Pay Status" span={3}>
            {studentDetail.price ? studentDetail.price : "No Paid"}
          </Descriptions.Item>
          <Descriptions.Item label="Extra Minutes" span={3}>
            {studentDetail.remaining_minutes}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  );
};

export default Detail;

export const NotificationDetail = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [count, setCount] = useState("");
  const handleOk = () => {
    setIsModalVisible(false);
    props.visibleCallback(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.visibleCallback(null);
  };
  const getUserDetail = () => {
    axios
      .get(`notification/count/${props.notification?._id}`)
      .then(function (response) {
        setCount(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (props.notification !== "" && props.notification !== null) {
      setIsModalVisible(true);
      getUserDetail();
    }
  }, [props]);

  return (
    <>
      <Modal
        title="Detail"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {props.notification?.title && (
          <H6>Title : {props.notification.title}</H6>
        )}
        {props.notification?.body && <H6>Body : {props.notification.body}</H6>}
        <Card
          withBorder
          radius="md"
          p="xl"
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
          })}
        >
          <Text size="xs" transform="uppercase" weight={700} color="dimmed">
            Views
          </Text>
          <Text size="lg" weight={500}>
            {count.data?.data.success} /{" "}
            {count.data?.data.success + count.data?.data.unsuccess}
          </Text>
          <Progress
            value={
              count.data?.data.success !== 0
                ? (count.data?.data.success * 100) /
                  (count.data?.data.success + count.data?.data.unsuccess)
                : 0
            }
            mt="md"
            size="lg"
            radius="xl"
          />
        </Card>
      </Modal>
    </>
  );
};
