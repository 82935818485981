import React, { useEffect, useState } from "react";
import {
  message,
  Popconfirm,
  Table,
  Space,
  Button,
  Row,
  Input,
  Modal,
  Avatar,
  Descriptions,
  Typography,
  InputNumber,
} from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";

const axios = require("axios").default;
const { Text } = Typography;

const Plans = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [studentDetail, setStudentDetail] = useState([]);
  const [plans, setPlans] = useState([]);
  const [extraMin, setExtraMin] = useState(0);

  const handleOk = () => {
    setIsModalVisible(false);
    props.visiblePlanCallback(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.visiblePlanCallback(null);
  };

  const planCancel = (plan_id) => {
    axios
      .get(`student/plan-cancel/${plan_id}`)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error)
      });
  };

  const getPlans = () => {
    setStudentDetail(props.plan);
    axios
      .get(`student/plans/${props.plan.user_no}`)
      .then(function (response) {
        setPlans(response.data.data);
        return response;
      })
      .catch(function (error) {
        console.log(error)
      });
  };

  const addExtraMin = (min) => {
    axios.get(`student/extra-min-add/${min}/${props.plan.user_no}`)
      .then(function (response) {
        studentDetail.remaining_minutes = response.data.data;
        return response;
      })
      .catch(function (error) {
          console.log(error)
      });
  };

  useEffect(() => {
    if (props.plan.length !== 0) {
      getPlans();
      setIsModalVisible(true);
    }
  }, []);

  const confirm = (plan_id) => {
    message.info("Canceled");
    planCancel(plan_id);
    getPlans();
  };

  const extraMinConfirm = () => {
    message.info("Minutes Added | " + extraMin);
    addExtraMin(extraMin);
  };

  const onExtraMinChange = (value) => {
    setExtraMin(value);
  };

  const columns = [
    {
      title: "Plan No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "last_name",
      key: "first_name",
      render: (text, record) => {
        return <>{record.first_name + " " + record.last_name}</>;
      },
    },
    {
      title: "Date",
      dataIndex: "schedule_date",
      key: "schedule_date",
    },
    {
      title: "Start",
      dataIndex: "start_time",
      key: "start_time",
    },
    {
      title: "End",
      dataIndex: "end_time",
      key: "end_time",
    },
    {
      title: "Student Joined",
      dataIndex: "katilim",
      key: "katilim",
      align: "center",
      render: (text, record) => {
        return (
          <>
            {record.katilim ? (
              <CheckCircleOutlined
                style={{ fontSize: "22px" }}
                twoToneColor="#52c41a"
              />
            ) : (
              <CloseCircleOutlined
                style={{ fontSize: "22px" }}
                twoToneColor="#eb2f96"
              />
            )}
          </>
        );
      },
    },
    {
      title: "Teacher Joined",
      dataIndex: "katilim",
      key: "katilim",
      align: "center",
      render: (text, record) => {
        return (
            <>
              {record.tkatilim ? (
                  <CheckCircleOutlined
                      style={{ fontSize: "22px" }}
                      twoToneColor="#52c41a"
                  />
              ) : (
                  <CloseCircleOutlined
                      style={{ fontSize: "22px" }}
                      twoToneColor="#eb2f96"
                  />
              )}
            </>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      align: "center",
      render: (text, record) => {
        return (
          <>
            {
              <Button
                type="primary"
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                Meet Detail
              </Button>
            }

            <Popconfirm
              placement="topLeft"
              title={"Are you sure ?"}
              onConfirm={() => confirm(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger>
                Cancel
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title="Talks and Plan"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Row>
          <Avatar
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "20px",
            }}
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            src={studentDetail.image_url + "/" + studentDetail.image}
          />
        </Row>

        <Row style={{ textAlign: "center" }}>
          <Space direction="vertical">
            <Text>{studentDetail.email}</Text>
          </Space>
        </Row>
        <Descriptions
          title={studentDetail.first_name + " " + studentDetail.last_name}
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          style={{ marginBottom: 30 }}
        >
          <Descriptions.Item label="Paid:">
            {studentDetail.price}
          </Descriptions.Item>
          <Descriptions.Item label="Montly:">
            {studentDetail.header}
          </Descriptions.Item>
          <Descriptions.Item label="Day:">
            {studentDetail.days}
          </Descriptions.Item>
          <Descriptions.Item label="Duration:">
            {studentDetail.duration}
          </Descriptions.Item>
          <Descriptions.Item label="Start:">
            {moment(studentDetail.start_date).format("DD-MM-YYYY h:mm")}
          </Descriptions.Item>
          <Descriptions.Item label="End:">
            {moment(studentDetail.end_date).format("DD-MM-YYYY h:mm")}
          </Descriptions.Item>
          <Descriptions.Item label="Extra Minutes:">
            {studentDetail.remaining_minutes}
          </Descriptions.Item>
          <Descriptions.Item label="Extra Minutes Add:">
            <Input.Group compact>
              <InputNumber min={-120} max={120} onChange={onExtraMinChange} />

              <Popconfirm
                placement="topLeft"
                title={"Are you sure ?"}
                onConfirm={extraMinConfirm}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Add</Button>
              </Popconfirm>
            </Input.Group>
          </Descriptions.Item>
        </Descriptions>

        {plans.length > 0 && (
          <Table pagination={true} columns={columns} dataSource={plans} />
        )}
      </Modal>
    </>
  );
};

export default Plans;
