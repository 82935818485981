import React, { useEffect, useState } from "react";
import {
    Modal,
} from "antd";
import { Card, Text,Flex } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import 'dayjs/locale/tr';
import Loader from "../atoms/Loader";
import styled from "styled-components";
import { Container } from '@mantine/core';
import Avatar from "../atoms/Avatar";
import moment from "moment/moment";
import qs from "qs";
const axios = require("axios").default;

const SalaryAndMinutes = ({salaryList,setModalVisible,modalVisible,payDateList}) => {

    const [loading, setLoading] = useState(false);
    const[list,setList] = useState({})

    const handleOk = () => {
        setModalVisible(false);

    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const handleSubmit = () => {
        setLoading(true)
        const data = {
            instructorId: salaryList.id,
            start: moment.utc(payDateList.reset_dates, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
            finish: moment.utc(payDateList.finish_dates, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
        };
        const qsData = qs.stringify(data);

        axios.post('/trainer/salary', qsData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                console.log("tranier/salary", response.data);
                setList(response.data.allMinutes)
            })
            .catch(error => {
                const message = error?.response?.data?.message || "An error occurred";
                showNotification({
                    id: "error-notification",
                    disallowClose: false,
                    autoClose: 5000,
                    title: "Error",
                    message: message,
                    color: "red",
                    icon: <IconX className="icon-xmark-circle" color="#fff" size={28} />,
                    className: "my-notification-class",
                    style: { backgroundColor: "white" },
                    sx: { backgroundColor: "white" },
                    loading: false,
                });
            }).finally(() => {
                setLoading(false)
        })
    };


    useEffect(() => {
        if (modalVisible) {
            handleSubmit();
        }
    }, [modalVisible]);




    return (
        <>
            <ModalX
                visible={modalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={1000}
                confirmLoading={true}
            >
                {loading ? (
                    <StyledLoad>
                        <Loader size={"sm"} />
                    </StyledLoad>
                ) : (
                    <>
                        {(
                          <Container>
                              <Flex
                                  id={"payment-wrap-list"}
                                  display={"flex"}
                                  direction={"column"}
                                  gap={"30px"}
                                  p={"1.5rem"}
                              >

                                  <Flex
                                      align={"center"}
                                  >
                                      <Flex
                                          align={"center"}
                                          gap={"50px"}
                                      >

                                          <Avatar
                                              type={3}
                                              src={`${salaryList.image_url}${salaryList.image}`}
                                              size={"150px"}
                                              borderRadius={"130px"}
                                          />
                                          <Flex
                                            direction={"column"}
                                            gap={"10px"}
                                          >
                                              <HeaderText>
                                                  {`${salaryList.first_name} ${salaryList.last_name}`}
                                              </HeaderText>
                                              <TextDate>
                                                  {`${payDateList.reset_dates} and  ${payDateList.finish_dates}`} between the dates
                                              </TextDate>
                                          </Flex>

                                      </Flex>

                                  </Flex>

                                  <Flex
                                      gap={"20px"}
                                  >

                                      <Cards
                                          shadow="sm"
                                          padding="xl"
                                          miw={"250px"}
                                          mih={"150px"}
                                      >
                                          <Text weight={500} size="lg" mt="sm" truncate={true}>
                                              Added Appointment
                                          </Text>

                                          <Text mt="md" color="dimmed" size="md" truncate={true}>
                                              {list.totalSchedule} min
                                          </Text>
                                      </Cards>

                                      <Cards
                                          shadow="sm"
                                          padding="xl"
                                          miw={"250px"}
                                          mih={"150px"}
                                      >

                                          <Text weight={500} size="lg" mt="sm" truncate={true}>
                                              Scheduled Appointment
                                          </Text>

                                          <Text mt="md" color="dimmed" size="md" truncate={true}>
                                              {list.scheduleByAppointment} min
                                          </Text>
                                      </Cards>
                                      <Cards
                                          shadow="sm"
                                          padding="xl"
                                          miw={"300px"}
                                          mih={"150px"}
                                      >

                                          <Text weight={500} size="lg" mt="sm"  truncate={true}>
                                              Number Classes Attended / min
                                          </Text>

                                          <Text mt="md" color="dimmed" size="md" truncate={true}>
                                              {`${list.successfulLesson} / ${list.successfulMinutes}`} min
                                          </Text>
                                      </Cards>


                                  </Flex>

                                  <Flex
                                      gap={"20px"}
                                  >

                                      <Cards
                                          shadow="sm"
                                          padding="xl"
                                          miw={"250px"}
                                          mih={"150px"}
                                      >

                                          <Text weight={500} size="lg" mt="sm" truncate={true}>
                                              Free Period
                                          </Text>

                                          <Text mt="md" color="dimmed" size="md" truncate={true}>
                                              {list.emptyAppointment} min
                                          </Text>
                                      </Cards>

                                      <Cards
                                          shadow="sm"
                                          padding="xl"
                                          miw={"250px"}
                                          mih={"150px"}
                                      >
                                          <Text weight={500} size="lg" mt="sm" truncate={true}>
                                              Extra Meeting Minute
                                          </Text>

                                          <Text mt="md" color="dimmed" size="md" truncate={true}>
                                              {list.totalMin} min
                                          </Text>
                                      </Cards>

                                  </Flex>
                              </Flex>
                          </Container>
                        )}
                    </>
                )}
            </ModalX>
        </>
    );
};



const StyledLoad = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`
const ModalX = styled(Modal)`
    top: 50px!important;
 
  .ant-modal-content{
    min-height: 300px;
  }
`

const HeaderText = styled.div`
  font-size: 55px;
  letter-spacing: 3px;
`
const TextDate = styled.div`
  color: #929298;
  font-size: 12px;
  letter-spacing: 0.4px;
  font-family: 'Roboto', sans-serif;
`

const Cards = styled(Card)`
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px!important;
`

export default SalaryAndMinutes;
