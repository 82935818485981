import { Modal, Button, Group, Text } from '@mantine/core';
import styled from "styled-components";

const ConfirmModal = ({ opened, setOpened, onDelete, onCancel,type,text }) => {
    const handleDeleteClick = () => {
        setOpened(true);
    };

    const confirmDelete = () => {
        setOpened(false);
        if (onDelete) {
            onDelete();
        }
    };

    const cancelDelete = () => {
        setOpened(false);
        if (onCancel) {
            onCancel();
        }
    };


    return (
        <>
            {
                type === "btn" && (
                    <Button size="xs" variant="filled" color="red" onClick={handleDeleteClick}>
                        {text ? text : "Tıkla"}
                    </Button>
                )
            }

            <ModalX
                opened={opened}
                onClose={cancelDelete}
                title="Deletion Confirmation"
                zIndex={"999999999999"}
            >
                <Text>Are you sure you want to delete?</Text>
                <Group position="right" spacing="xs" mt="md">
                    <Button size="xs" variant="outline" color="gray" onClick={cancelDelete}>
                        Cancel
                    </Button>
                    <Button size="xs" variant="filled" color="red" onClick={confirmDelete}>
                        Delete
                    </Button>
                </Group>
            </ModalX>
        </>
    );
};


const ModalX = styled(Modal)`
  .mantine-Modal-header {
    border-bottom: 1px solid #ececec;
    margin-bottom: 20px;
  }
`

export default ConfirmModal;
