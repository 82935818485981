import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Pagination,
  Drawer,
  Row,
  Col,
  Card,
  Form,
  Button,
} from "antd";
import { Table, Panel, Input } from "rsuite";
import moment from "moment";
import CustomModal from "./modal";
import styled from "styled-components";
import ConfirmModal from "../atoms/ConfirmModal";
import {showNotification, updateNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";


const axios = require("axios").default;
const Discounts = () => {
  const [discount, setDiscount] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  let { pageNumber } = useParams();
  const [filterVisible, setFilterVisible] = useState(false);
  const [filters, setFilters] = useState();
  const { Column, HeaderCell, Cell } = Table;
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const[list,setList] = useState([]);
  const[opened,setOpened] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const onFilterClose = () => {
    setFilterVisible(false);
  };


  const getList = (pageNumber, filters = {}) => {
    let params = {
      pageNumber,
      filters,
    };
    axios
      .get(`discount/list`, { params })
      .then(function (response) {
        setDiscount(response.data.result.list);
        setTotalRecord(response.data.result.items.total);
        return response;
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    getList(pageNumber);
  }, [ pageNumber]);

  const onUserPageChange = (pageNumber) => {
    getList(pageNumber, filters);
  };



  const openConfirmModal = (rowData) => {
    setSelectedRow(rowData);
    setOpened(true);
  };

  const handleConfirmDelete = () => {
    if (selectedRow) {
      deleteDiscount(selectedRow);
      setOpened(false);
    }
  };

  const handleCancelDelete = () => {
    setOpened(false);
  };




  const deleteDiscount = (p1) => {
    axios.get(`/discount/delete/${p1.id}`)
        .then((res) => {
          console.log(res.data.status);

          showNotification({
            id: 'load-data',
            autoClose: false,
            disallowClose: true,
            loading: true,
            title: "Please Wait",
            message: "The request is being processed...",
          });
          setTimeout(() => {
            updateNotification({
              id: 'load-data',
              color: 'teal',
              title: "Success",
              message: "The notification will close in 2 seconds, you can close this notification now",
              icon: <IconCheck size="1rem" />,
              autoClose: 2000,
            });
          }, 1000);
          setTimeout(() => {
            getList(pageNumber)
          }, 2000);
        })
        .catch((error) => {
          const message = error?.response?.data?.message || "An error occurred";
          showNotification({
            id: "error-notification",
            disallowClose: false,
            autoClose: 5000,
            title: "Error",
            message: message,
            color: "red",
            icon: <IconX size="1rem" />,
            className: "my-notification-class",
            style: { backgroundColor: "white" },
            sx: { backgroundColor: "white" },
            loading: false,
          });
        });
  };



  const handleFilter = async (filt) => {
    await setFilters(filt);
    await getList(pageNumber, filt);
  };
  return (
    <>

      <Drawer
        title="Detail Search"
        placement="right"
        onClose={onFilterClose}
        visible={filterVisible}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          name="exam_form"
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={true}
          onFinish={handleFilter}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name={"first_name"} label="Name">
                <Input placeholder="name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="last_name" label="Last Name">
                <Input placeholder="last_name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="email" label="Email">
                <Input placeholder="Email" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="subject" label="Title">
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
          </Row>

          <Button type="primary" block htmlType="submit">
            Ara
          </Button>
        </Form>
      </Drawer>
      <Card
        type="inner"
        title={
          <h1 className="h3 mb-3">
            <strong>DİSCOUNT CODES </strong> LIST ({totalRecord})
          </h1>
        }
        extra={
          <>
            <CustomModal
            showModal={showModal}
            setShowModal={setShowModal}
            setEditModal={setEditModal}
            editModal={editModal}
            list={list}
            discount={discount}
            onUpdate={getList }
            />
          </>
        }
      >
        <Panel>
          <Table height={1450} data={discount} onRowClick={(rowData) => {}}>
            <Column flexGrow={1}>
              <HeaderCell>Code</HeaderCell>
              <Cell dataKey="code" />
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Percent</HeaderCell>
              <Cell dataKey="percent" />
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Month</HeaderCell>
              <Cell dataKey="month" />
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Discount</HeaderCell>
              <Cell dataKey="discount_yuzde" />
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>Start Date</HeaderCell>
              <Cell dataKey="start">
                {(rowData) => (
                  <p className={"text-truncate"}>
                    {moment(rowData.start).format("DD-MM-YYYY h:mm")}
                  </p>
                )}
              </Cell>
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Finish Date</HeaderCell>
              <Cell dataKey="finish">
                {(rowData) => (
                  <p className={"text-truncate"}>
                    {moment(rowData.finish).format("DD-MM-YYYY h:mm")}
                  </p>
                )}
              </Cell>
            </Column>

            <Column >
              <HeaderCell></HeaderCell>
              <Cell dataKey="isActive">
                {(rowData) => (
                    <>
                      {rowData && (
                          <Button
                              type="primary"
                              onClick={() => {
                                setEditModal(true)
                                setList(rowData)
                              }

                          }
                              style={{ backgroundColor: "#9e0c48", border: "none" }}
                          >
                            Edit
                          </Button>

                      ) }
                    </>
                )}
              </Cell>
            </Column>

            <Column >
              <HeaderCell></HeaderCell>
              <Cell dataKey="isActive">
                {(rowData) => (
                  <>
                    {rowData.isActive == "YES" ? (
                        <div>
                          <Button
                              type="primary"
                              onClick={() => {
                                openConfirmModal(rowData);
                              }}
                              style={{ backgroundColor: "#9e0c48", border: "none" }}
                          >
                            Delete
                          </Button>
                          <ConfirmModal
                              opened={opened}
                              setOpened={setOpened}
                              onCancel={handleCancelDelete}
                              onDelete={handleConfirmDelete}
                          />
                        </div>
                    ) : (
                      <Button disabled>Delete</Button>
                    )}
                  </>
                )}
              </Cell>
            </Column>
          </Table>
        </Panel>
        {discount.length > 0 && (
          <Panel>
            <StyledPagination
              defaultCurrent={pageNumber}
              total={totalRecord}
              onChange={onUserPageChange}
              recordPerPage={5}
            />
          </Panel>
        )}
      </Card>
    </>
  );
};



const StyledPagination = styled(Pagination)`
  max-width: 500px;
  margin: 0 auto;
`;


export default Discounts;
