import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import Trainers from "./pages/trainers/trainers";
import Students from "./pages/students/students";
import Support from "./pages/support/supports";
import Discounts from "./pages/discount/discounts";
import Login from "./common/auth";
import Notification from "./pages/notification/notification";
import InfoRequest from './pages/InfoRequest/InfoRequest';

function AllRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route path="login" element={<Login />} />
        <Route path="trainers" element={<Trainers />} />
        <Route path="students" element={<Students />} />
        <Route path="supports" element={<Support />} />
        <Route path="discounts" element={<Discounts />} />
        <Route path="notification" element={<Notification />} />
        <Route path="infoRequest" element={<InfoRequest />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Böyle bişey yok!</h2>
      <p>
        <Link to="/">git</Link>
      </p>
    </div>
  );
}

export default AllRoutes;
