import React, { useEffect, useState } from "react";
import { Row, Modal, Avatar, Typography, Table, Form, Input, Spin } from "antd";
import { Panel, Button } from "rsuite";
const axios = require("axios").default;
const { Title, Text } = Typography;
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const SupportDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [supportDetail, setSupportDetail] = useState("");
  const [studentDetail, setStudentDetail] = useState([]);
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const Loading = () => {
    return loading ? <Spin /> : "";
  };

  const handleOk = () => {
    setIsModalVisible(false);
    props.visibleSupportCallback(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.visibleSupportCallback(null);
  };

  const getDetail = () => {
    setLoading(true);
    axios
      .get(`support/detail/${props.detail.id}/${props.detail.sender_id}`)
      .then(function (response) {
        console.log(response.data.data);
        setSupportDetail(response.data.data);
        setLoading(false);
        return response;
      })
      .catch(function (error) {
            console.log(error)
      });
  };

  useEffect(() => {
    if (props.detail.length !== 0) {
      setStudentDetail(props.detail);
      getDetail();
      setIsModalVisible(true);
    }
  }, []);

  const columns = [
    {
      title: "",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <>
          {record.first_name == "QARINT" ? (
            <Text mark>{record.first_name}</Text>
          ) : (
            <Text>{record.first_name}</Text>
          )}
        </>
      ),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text, record) => (
        <>
          <Row style={{ textAlign: "left" }}>
            <Title level={5}>{record.subject}</Title>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Text>{record.message}</Text>
          </Row>

          <Row style={{ marginBottom: 15 }}>
            <Text>
              {record.support_image ? (
                <img src={record.support_image} alt="" width={120} />
              ) : null}
            </Text>
          </Row>
          <Row style={{ textAlign: "left" }}>
            <Text type="success">{record.created_at}</Text>
          </Row>
          <Row style={{ textAlign: "left" }}>
            <Text type="success">{record.timezone}</Text>
          </Row>
          <Row style={{ textAlign: "left" }}>
            <Text type="success">{record.country}</Text>
          </Row>
        </>
      ),
    },
  ];

  const onFinish = (values) => {
    setLoading(true);
    values.sender_id = 211;
    values.receiver_id = studentDetail.sender_id;
    axios
      .post(`support/send`, values)
      .then(function (response) {
        setLoading(false);
        getDetail();
        form.setFieldsValue({ message: "" });
        form.setFieldsValue({ subject: "" });
        return response;
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          window.location.reload(false);
        }
      });
  };

  return (
    <>
      <Modal
        title="SUPPORT MESSAGES"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Panel style={{ textAlign: "center" }}>
          <Row>
            <Avatar
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "20px",
              }}
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              src={studentDetail.image_url + "/" + studentDetail.image}
            />
          </Row>

          <Row style={{ textAlign: "center" }}>
            <Title level={3}>
              {studentDetail.first_name + " " + studentDetail.last_name}
            </Title>
          </Row>

          <Row style={{ textAlign: "center" }}>
            <Text type="secondary">{studentDetail.email}</Text>
          </Row>
        </Panel>

        <Panel header="Cevap Yaz" shaded>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Title"
              name="subject"
              rules={[{ required: true, message: "you must write a title" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Answer"
              name="message"
              rules={[{ required: true, message: "you must write a answer" }]}
            >
              <TextArea showCount style={{ height: 120 }} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                {!loading ? "Kaydet" : <Loading />}
              </Button>
            </Form.Item>
          </Form>
        </Panel>

        {supportDetail.length > 0 && (
          <Panel shaded>
            <Table
              pagination={false}
              columns={columns}
              dataSource={supportDetail}
            />
          </Panel>
        )}
      </Modal>
    </>
  );
};

export default SupportDetail;
