import { Button } from '@mantine/core';
import styled from 'styled-components';
const CustomButton = ({disabled,loading,text,radius,color,variant,leftIcon,onClick,type,size,bordercolor,fontsize,background,hoverBackground,hoverColor,hoverBordercolor,marginRight,BackgrodDisabled}) => {
    return (
        <Buttons
            variant={variant ?? "filled"}
            leftIcon={leftIcon !== undefined ? leftIcon : null}
            loading={loading}
            loaderPosition="center"
            disabled={disabled}
            radius={radius}
            color={color}
            onClick={onClick}
            type={type}
            size={size}
            bordercolor={bordercolor}
            fontsize={fontsize}
            background={background}
            hoverBackground={hoverBackground}
            hoverColor={hoverColor}
            hoverBordercolor={hoverBordercolor}
            marginRight={marginRight}
            BackgrodDisabled={BackgrodDisabled}
        >
            {text}
        </Buttons>
    );
}

const Buttons = styled(Button)`
  color: ${({ color }) => (color ? color : '#ffffff')};
  background: ${({ background }) => (background ? background : 'rgb(158, 12, 72)' )};
  border-color: ${({ bordercolor }) => (bordercolor ? bordercolor : '#ffffff')};
  font-size: ${({ fontsize }) => (fontsize ? fontsize : '14px')};

  .mantine-Button-icon{
    margin-right: ${({marginRight}) => marginRight};
  }

  &.mantine-UnstyledButton-root:disabled{
    background-color: ${({ BackgrodDisabled }) => BackgrodDisabled};
  }
  
  &:active {
    transform: scale(1);
  }
  &:hover {
    background: ${({ hoverBackground }) => (hoverBackground ? hoverBackground : 'rgb(130, 10, 60)')};
    border-color: ${({ hoverBordercolor }) => (hoverBordercolor ? hoverBordercolor : '#ffffff')};
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : '#ffffff')};
  }
  
`;

export default CustomButton