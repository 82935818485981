import React, { useEffect, useState } from "react";
import {
    Modal,
} from "antd";
import { showNotification,updateNotification } from '@mantine/notifications';
import {IconX, IconTrash, IconCheck} from '@tabler/icons-react';
import { Flex,Table,Text} from '@mantine/core';
import 'dayjs/locale/tr';
import dayjs from 'dayjs';
import Loader from "../atoms/Loader";
import styled from "styled-components";
import CustomButton from "../atoms/Button";
import DataNotFound from "../atoms/DataNotFound";
import ConfirmModal from "../atoms/ConfirmModal";
import Avatar from "../atoms/Avatar";
const axios = require("axios").default;

const CreditStatus = ({visiblePlanCallback,creditList,creditModal,setCreditModal,getUserList}) => {
    const [loading, setLoading] = useState(false);
    const[creditIssuerList ,setCreditIssuerList ] = useState([])
    const [opened, setOpened] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);


    dayjs.locale('tr');
    const handleOk = () => {
        setCreditModal(false);
        visiblePlanCallback(null);
    };

    const handleCancel = () => {
        setCreditModal(false);
        visiblePlanCallback(null);
    };



    const cancelDelete = () => {
        setOpened(false);
    };


    const getCreditIssuer = () => {
        setLoading(true);
        axios.get(`https://qapi.qarint.com/student/credit/${creditList.user_no}`)
            .then(function (response) {

                setCreditIssuerList(response?.data?.credit);
                setLoading(false);
                return response;
            })
            .catch(function (error) {
                console.log(error)
            });
    };


    useEffect(() => {
        if (creditList?.length !== 0) {
            getCreditIssuer();
        }
    }, [creditModal]);




    return (
        <ModalX
            title={"Credit Usage Status"}
            visible={creditModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={600}
            confirmLoading={true}
            bodyStyle={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}
        >
                {loading ? (
                    <StyledLoad>
                        <Loader size={"sm"} />
                    </StyledLoad>
                ) : (
                        <Flex id={"credit-wrap-list"} display={"flex"} direction={"column"} gap={"30px"}>

                            <Flex
                                align={"center"}
                            >
                                <Flex
                                    align={"center"}
                                    gap={"50px"}
                                >

                                    <Avatar
                                        background={"transparent"}
                                        type={3}
                                        src={`${creditList.image_url}/${creditList.image}`}
                                        size={"140px"}
                                        borderRadius={"130px"}
                                    />

                                        <Flex
                                        direction={"column"}
                                        >
                                            <Text
                                            truncate={true}
                                            size={"45px"}
                                            style={{textTransform: "capitalize"}}
                                            >
                                                {`${creditList.first_name} ${creditList.last_name}`}
                                            </Text>
                                            <Text
                                                truncate={true}
                                                size={"xs"}
                                                color={"#929298"}
                                                style={{letterSpacing: "1px"}}
                                            >{creditList.email}</Text>
                                        </Flex>
                                </Flex>

                            </Flex>


                            <Flex display={"flex"} direction={"column"} gap={"30px"}>
                                <Table
                                 horizontalSpacing="xl"
                                 striped
                                 highlightOnHover
                                >
                                    <thead>
                                        <tr>
                                            <th>Remaining Compensation</th>
                                            <th>Make-up Lesson</th>
                                            <th>Remaining Lesson</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {creditIssuerList.length === 0 ? (
                                                <DataNotFound
                                                    top={"50%"}
                                                    left={"50%"}
                                                    position={"fixed"}
                                                />
                                        ) : (
                                            creditIssuerList.map((element) => {
                                                return (
                                                    <tr key={element?.id}>
                                                        <td>{element.kalanTelafi}</td>
                                                        <td>{element.telafiDersi}</td>
                                                        <td>{element.kalanDers}</td>
                                                    </tr>
                                                );
                                            })
                                    )}
                                    </tbody>
                                </Table>
                            </Flex>
                        </Flex>
                )}
            </ModalX>
    );
};



const StyledLoad = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`
const ModalX = styled(Modal)`
  .ant-modal-content{
    min-height:530px;
  }
`


export default CreditStatus;
