import React, { useEffect, useState, useRef } from "react";
import { DatePickerInput } from "@mantine/dates";
import { Flex} from '@mantine/core';
import moment from "moment";
import qs from "qs";
import { default as axios } from "axios";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import CustomButton from "../atoms/Button";
import CustomModal from "../atoms/Modal";

const PayDate = ({setPayDateList,getTrainerList}) => {
    const [startDate, setStartDate] = useState(null);
    const [finishDate, setFinishDate] = useState(null);
    const modalRef = useRef();

    const handleSubmit = () => {
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            finish_date: moment(finishDate).format('YYYY-MM-DD'),
        };

        const qsData = qs.stringify(data);

        axios.post('/trainer/salaryDateUpdate', qsData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                console.log("salaryDateUpdate", response);
                const reset_dates = moment.utc(startDate).format('DD-MM-YYYY HH:mm:ss');
                const finish_dates = moment.utc(finishDate).format('DD-MM-YYYY HH:mm:ss');
                console.log("salaryDateUpdate",reset_dates,finish_dates)

                setPayDateList({ reset_dates, finish_dates });

                showNotification({
                    id: 'load-data',
                    autoClose: false,
                    disallowClose: true,
                    loading: true,
                    title: "Please Wait",
                });

                setTimeout(() => {
                    updateNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: "Success",
                        message: "The notification will close in 2 seconds, you can close this notification now",
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);

                setTimeout(() => {
                    handleCloseModal();
                    getTrainerList();
                }, 2000);
            })
            .catch(error => {
                const message = error?.response?.data?.message || "An error occurred";
                showNotification({
                    id: "error-notification",
                    disallowClose: false,
                    autoClose: 5000,
                    title: "Error",
                    message: message,
                    color: "red",
                    icon: <IconX className="icon-xmark-circle" color="#fff" size={28} />,
                    className: "my-notification-class",
                    style: { backgroundColor: "white" },
                    sx: { backgroundColor: "white" },
                    loading: false,
                });
            });
    };

    const handleEdit = () => {
        axios.get('/trainer/salaryDate')
            .then(response => {
                const data = response.data;

                if (data.status === "success" && Array.isArray(data.list) && data.list.length > 0) {
                    const { reset_date, finish_date } = data.list[0];
                    setStartDate(reset_date ? new Date(reset_date) : null);
                    setFinishDate(finish_date ? new Date(finish_date) : null);

                    const reset_dates = moment.utc(reset_date).format('DD-MM-YYYY HH:mm:ss');
                    const finish_dates = moment.utc(finish_date).format('DD-MM-YYYY HH:mm:ss');
                    setPayDateList({ reset_dates, finish_dates });
                }


            })
            .catch(error => {
                const message = error?.response?.data?.message || "An error occurred";
                showNotification({
                    id: "error-notification",
                    disallowClose: false,
                    autoClose: 5000,
                    title: "Error",
                    message: message,
                    color: "red",
                    icon: <IconX size={28} />,
                });
            });
    };


    useEffect(() => {
        handleEdit();
    }, []);




    const handleCloseModal = () => {
        if (modalRef.current) {
            modalRef.current.close();
        }
    };


    return (
        <>
            <CustomModal
                ref={modalRef}
                Btn="Pay Date"
                size="md"
                height="250px"
                title={"Pay Date"}
                titleColor={"#000"}
                content={
                    <>
                        <Flex
                            direction="column"
                            align="stretch"
                            gap={"30px"}
                            mt={"20px"}
                        >
                            <DatePickerInput
                                valueFormat="DD-MM-YYYY"
                                value={startDate}
                                label="Start Date"
                                placeholder="Pick one"
                                clearable={true}
                                onChange={date => setStartDate(date)}
                                locale="tr"
                                dropdownType="modal"
                            />
                            <DatePickerInput
                                valueFormat="DD-MM-YYYY"
                                value={finishDate}
                                label="Finish Date"
                                placeholder="Pick one"
                                clearable={true}
                                onChange={date => setFinishDate(date)}
                                locale="tr"
                                dropdownType="modal"
                            />
                            <Flex
                                justify="flex-end"
                                mt={"5px"}
                            >
                                <CustomButton text="Submit" onClick={handleSubmit} />
                            </Flex>
                        </Flex>
                    </>
                }
            />
        </>
    );
};

export default PayDate;