import React, {useEffect, useState, Fragment} from "react";
import {useParams} from "react-router-dom";
import {
    Table,
    Button,
    Pagination,
    Drawer,
    Form,
    Row,
    Col,
    Input,
    Card,
    Tooltip
} from "antd";
import moment from "moment";
import styled from 'styled-components';
import {Flex} from "@mantine/core";
import Loader from "../atoms/Loader"
const axios = require("axios").default;


const InfoRequest = () => {
    const [infoList, setInfoList] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    let {pageNumber} = useParams();
    let {pagePer} = useParams();
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState();
    const [filterVisible, setFilterVisible] = useState(false);


    const columns = [

        {
            title: "Name",
            dataIndex: "firstname",
            key: "firstname",
            ellipsis: true,

        },
        {
            title: "Last Name",
            dataIndex: "lastname",
            key: "lastname",
            ellipsis: true,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            ellipsis: true,
        },
        {
            title: "info",
            dataIndex: "info",
            key: "info",
            ellipsis: {
                showTitle: false,
            },
            render: (info) => (
                <Tooltip placement="topLeft" title={info} label={info}>
                    {info}
                </Tooltip>
            ),
        },
        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
            ellipsis: true,
        },


        {
            title: "Date",
            dataIndex: "created_at",
            key: "created_at",
            render: (text, record) => {
                return <>{moment(record.date).format("DD-MM-YYYY h:mm")}</>;
            },
            ellipsis: true,
        },

    ];

    const getUserList = (pageNumber, filters = {}, per) => {
        let params = {
            pageNumber,
            filters,
            pagePer,
        };

        setLoading(true);
        axios.get(`https://qapi.qarint.com/support/info`, {params}).then(function (response) {
            setInfoList(response.data.result);
            setTotalRecord(response.data.result.length);
            return response;
        })
            .catch(function (error) {
                console.log(error)
            }).finally(() => {
            setLoading(false);
        })
    };

    useEffect(() => {
        console.log("Fetching user list for page number:", pageNumber);
        getUserList(pageNumber);
    }, [pageNumber]);

    const onUserPageChange = (pageNumber, pagePer) => {
        getUserList(pageNumber, filters, pagePer);
    };

    const handleFilter = async (filt) => {
        await setFilters(filt);
        await getUserList(pageNumber, filt, pagePer);
    };



    const showFilterDrawer = () => {
        setFilterVisible(true);
    };

    const onFilterClose = () => {
        setFilterVisible(false);
    };


    return (
        <>
            <Drawer
                title="Detail Search"
                placement="right"
                onClose={onFilterClose}
                visible={filterVisible}
            >
                <Form
                    layout="vertical"
                    hideRequiredMark
                    name="exam_form"
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{flex: 1}}
                    colon={true}
                    onFinish={handleFilter}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name={"firstname"} label="Name">
                                <Input placeholder="name"/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="lastname" label="Last Name">
                                <Input placeholder="last_name"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="email" label="Email">
                                <Input placeholder="Email"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button type="primary" block htmlType="submit">
                        Ara
                    </Button>
                </Form>
            </Drawer>



            <Card
                type="inner"
                title={
                    <h1 className="h3 mb-3">
                        <strong>INFO REQUEST</strong> LİST ({totalRecord}){" "}
                    </h1>
                }
                extra={
                    <Flex
                        display={"flex"}
                        gap={"50px"}
                        align={"center"}
                    >


                        <Button
                            type="primary"
                            style={{backgroundColor: "#9e0c48", border: "none"}}
                            onClick={showFilterDrawer}
                        >
                            Filter
                        </Button>
                    </Flex>
                }
            >
                {loading ? (
                    <StyledLoad>
                        <Loader size={"sm"} />
                    </StyledLoad>
                ) : (
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={infoList}
                    />
                )}
                {infoList.length > 0 && (
                    <Pagination
                        defaultCurrent={pageNumber}
                        total={totalRecord}
                        onChange={onUserPageChange}
                        pageSizeOptions={[10, 10000]}
                        showSizeChanger={false}
                    />
                )}
            </Card>
        </>
    );
};



const StyledLoad = styled.div`
    position: absolute;
    left: 50%;
    top: 200%;
    transform: translate(-50%,-50%);
`

export default InfoRequest;
