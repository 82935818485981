import React, { useEffect, useState } from "react";
import {
  message,
  Popconfirm,
  Space,
  Button,
  Row,
  Input,
  Modal,
  Avatar,
  Descriptions,
  Typography,
  InputNumber,
} from "antd";
const axios = require("axios").default;
const {  Text } = Typography;

const Extramin = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [studentDetail, setStudentDetail] = useState([]);
  const [extraMin, setExtraMin] = useState(0);

  const handleOk = () => {
    setIsModalVisible(false);
    props.visibleExtraMinCallback(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.visibleExtraMinCallback(null);
  };

  const addExtraMin = (min) => {
    axios
      .get(`student/extra-min-add/${min}/${props.extramin.user_no}`)
      .then(function (response) {
        studentDetail.remaining_minutes = response.data.data;
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.extramin.length !== 0) {
      setStudentDetail(props.extramin);
      setIsModalVisible(true);
    }
  }, []);

  const extraMinConfirm = () => {
    message.info("Minutes Added | " + extraMin);
    addExtraMin(extraMin);
  };

  const onExtraMinChange = (value) => {
    setExtraMin(value);
  };

  return (
    <>
      <Modal
        title="Ekstra Minutes"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Row>
          <Avatar
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "20px",
            }}
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            src={studentDetail.image_url + "/" + studentDetail.image}
          />
        </Row>

        <Row style={{ textAlign: "center" }}>
          <Space direction="vertical">
            <Text>{studentDetail.email}</Text>
          </Space>
        </Row>
        <Descriptions
          title={studentDetail.first_name + " " + studentDetail.last_name}
          bordered
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          style={{ marginBottom: 30 }}
        >
          <Descriptions.Item label="Extra Minutes:">
            {studentDetail.remaining_minutes}
          </Descriptions.Item>
          <Descriptions.Item label="Add :">
            <Input.Group compact>
              <InputNumber min={-120} max={120} onChange={onExtraMinChange} />

              <Popconfirm
                placement="topLeft"
                title={"Are you sure ?"}
                onConfirm={extraMinConfirm}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Add</Button>
              </Popconfirm>
            </Input.Group>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  );
};

export default Extramin;
