import React, { useEffect, useState } from "react";
import { NotificationDetail } from "../components/Detail";
import { Table } from "antd";
import styled from "styled-components";
import { Dropdown } from "rsuite";

const StyledTable = styled(Table)`
  width: 70%;
  > div > div > ul {
    display: flex;
    justify-content: center;
  }
`;

const NotFound = styled.div`
  margin-top: 35px;
  text-align: center;
`;
const axios = require("axios").default;
const NotificationList = () => {
  const [notification, setNotification] = useState([]);
  const [id, setId] = useState();
  const [notificationDetail, setNotificationDetail] = useState("");

  const showDetailModal = (detail) => {
    setNotificationDetail(detail);
  };
  const notificationcolumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Body",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <>
          <Dropdown title="Functions">
            <Dropdown.Item onClick={() => showDetailModal(record)}>
              Detail
            </Dropdown.Item>
          </Dropdown>
        </>
      ),
    },
  ];
  useEffect(() => {
    axios
      .get(`notification/list`)
      .then(function (response) {
        setNotification(response?.data.data);
        return response;
      })
      .catch(function (error) {
        window.location.reload(false);
        console.log(error);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`notification/count/${id}`)
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        window.location.reload(false);
        console.log(error);
      });
  }, [id]);

  return (
    <>
      {notification.length > 0 ? (
        <>
          <h1 className="h3 m-3">
            <strong>NOTIFICATION</strong> LIST
          </h1>
          <StyledTable
            columns={notificationcolumns}
            dataSource={notification}
          />
          <NotificationDetail
            notification={notificationDetail}
            visibleCallback={showDetailModal}
          />
        </>
      ) : (
        <NotFound>Listelenecek bildirim bulunmamaktadır.</NotFound>
      )}
    </>
  );
};

export default NotificationList;
