import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import {Notifications} from '@mantine/notifications';
import {MantineProvider} from '@mantine/core';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <React.StrictMode>
        <BrowserRouter>
            <MantineProvider withNormalizeCSS withGlobalStyles>
                <MantineProvider
                    withNormalizeCSS
                    withGlobalStyles
                    theme={{ colorScheme: 'dark' ? 'light' : 'dark' }}
                >
                    <Notifications position="top-left" zIndex={999999} />
                    <App />
                </MantineProvider>
            </MantineProvider>
        </BrowserRouter>
    </React.StrictMode>
  // <React.StrictMode>
  //     <BrowserRouter>
  //       <App />
  //     </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
