import React from "react";
import ConfirmModal from "../atoms/ConfirmModal";
import {showNotification, updateNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";

const axios = require("axios").default;

const TrainerDelete = ({trainerList,isOpened,setIsOpened,getTrainerList}) => {


    const PlanDelete = () => {
        axios.get(`trainer/delete/${trainerList.id}`)
            .then(response => {
                console.log(response);
                showNotification({
                    id: 'load-data',
                    autoClose: false,
                    disallowClose: true,
                    loading: true,
                    title: "Please Wait",
                });

                setTimeout(() => {
                    updateNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: "Success",
                        message: "The notification will close in 2 seconds, you can close this notification now",
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
                getTrainerList()
            })
            .catch(error => {
                const message = error?.response?.data?.message || "An error occurred";
                showNotification({
                    id: "error-notification",
                    disallowClose: false,
                    autoClose: 5000,
                    title: "Error",
                    message: message,
                    color: "red",
                    icon: <IconX className="icon-xmark-circle" color="#fff" size={28} />,
                    className: "my-notification-class",
                    style: { backgroundColor: "white" },
                    sx: { backgroundColor: "white" },
                    loading: false,
                });
            })
            .finally(() => {
                setTimeout(() => {
                    setIsOpened(false);
                }, 2000);
            });
    };



    const confirmDelete = () => {
        setIsOpened(false);
        PlanDelete();
    };

    const cancelDelete = () => {
        setIsOpened(false);
    };


    return (
        <>
          <ConfirmModal
            opened={isOpened}
            setOpened={setIsOpened}
            onCancel={cancelDelete}
            onDelete={confirmDelete}

            />
        </>
    );
};

export default TrainerDelete;
