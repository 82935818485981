import logo from './logo.svg';
import AllRoutes from './routes';
import Layout from './layout';
import {StoreProvider} from "./common/store";
import Auth from './common/auth';

function App() {
  return (
    <div className="App">
        <StoreProvider>
            <Auth>
                <Layout body={<AllRoutes />}/>
            </Auth>
        </StoreProvider>
    </div>
  );
}

export default App;
