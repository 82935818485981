import { useState } from "react";
import { createStyles, Navbar, Group, Code } from "@mantine/core";
import {
  IconChartPie2,
  IconUsers,
  IconSchool,
  IconHelp,
  IconTag,
  IconBell,
  IconFileInfo
} from "@tabler/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";

const useStyles = createStyles((theme, _params) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: theme.spacing.md * 1.5,
    borderBottom: `1px solid ${
        theme.colorScheme === "dark"
            ? theme.colors.dark[4]
            : theme.colors.gray[2]
    }`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `1px solid ${
        theme.colorScheme === "dark"
            ? theme.colors.dark[4]
            : theme.colors.gray[2]
    }`,
  },

  link: {
    ...theme.focusStyles,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[1],
      color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[9],

      "& $linkIcon": {
        color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[9],
      },
    },
  },

  linkIcon: {
    marginRight: theme.spacing.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[6],
  },

  linkActive: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[1],
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[9],

    "& $linkIcon": {
      color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[9],
    },
  },
}));

const StyledLink = styled(Link)`
  width: 100%;
  padding: 10px 12px;
  color: #868e96;

  &:hover {
    color: #ae094d;
    text-decoration: none;
  }

  &:focus {
    color: #ae094d !important;
    text-decoration: none;
  }
`;

const data = [
  { url: "/", name: "Dashboard", icon: IconChartPie2 },
  { url: "/trainers", name: "Tutor List", icon: IconUsers },
  { url: "/students", name: "Student List", icon: IconSchool },
  { url: "/supports", name: "Support", icon: IconHelp },
  { url: "/discounts", name: "Discounts", icon: IconTag },
  { url: "/notification", name: "Notification", icon: IconBell },
  { url: "/infoRequest", name: "Request for Information", icon: IconFileInfo },
];

export function NavbarSimple() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("");

  const links = data.map((item) => (
      <a
          key={item.url}
          className={cx(classes.link, {
            [classes.linkActive]: item.name === active,
          })}
          onClick={() => {
            setActive(item.name);
          }}
      >
        <StyledLink to={item.url}>
          <item.icon className={classes.linkIcon} size={24} />
          <span>{item.name}</span>
        </StyledLink>
      </a>
  ));

  return (
      <Navbar p="md">
        <Navbar.Section grow>
          <Group className={classes.header} position="apart">
            <h3 style={{ color: "#AE094D" }}>QADMIN</h3>
            <Code sx={{ fontWeight: 700 }}>v0.1.0</Code>
          </Group>
          {links}
        </Navbar.Section>
      </Navbar>
  );
}
