import React, {useEffect} from 'react';
import moment from 'moment';
import qs from 'qs';
import { Button, DatePicker, Input, Select, Form } from 'antd';
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
const axios = require('axios').default;
const { Option } = Select;

const DiscountForm = ({ onHideModal,modalType,list,onUpdate}) => {
    const [form] = Form.useForm();
    const handleSubmit = (values) => {
        const data = {
            code: values.code,
            start: moment(values.start).format('YYYY-MM-DD HH:mm:ss'),
            finish: moment(values.finish).format('YYYY-MM-DD HH:mm:ss'),
            month: values.month,
            percent: values.percent,
            discount_show: values.discount,
        };

        const qsData = qs.stringify(data);

        axios.post('/discount/add', qsData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                console.log(response);

                showNotification({
                    id: 'load-data',
                    autoClose: false,
                    disallowClose: true,
                    loading: true,
                    title: "Please Wait",
                });

                setTimeout(() => {
                    updateNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: "Success",
                        message: "The notification will close in 2 seconds, you can close this notification now",
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);

                setTimeout(() => {
                    onHideModal();
                    onUpdate()
                    form.resetFields();
                }, 2000);
            })
            .catch(error => {
                const message = error?.response?.data?.message || "An error occurred";
                showNotification({
                    id: "error-notification",
                    disallowClose: false,
                    autoClose: 5000,
                    title: "Error",
                    message: message,
                    color: "red",
                    icon: <IconX className="icon-xmark-circle" color="#fff" size={28} />,
                    className: "my-notification-class",
                    style: { backgroundColor: "white" },
                    sx: { backgroundColor: "white" },
                    loading: false,
                });
            })
            .finally(() => {
                console.log("Final cleanup or actions here");
            });
    };



    const editUpdate = (values) => {
        const data = {
            id:list.id,
            code: values.code,
            start: moment(values.start).format('YYYY-MM-DD HH:mm:ss'),
            finish: moment(values.finish).format('YYYY-MM-DD HH:mm:ss'),
            month: values.month,
            percent: values.percent,
            discount_show: values.discount,
        };

        const qsData = qs.stringify(data);

        axios.post('/discount/update', qsData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                console.log(response);

                showNotification({
                    id: 'load-data',
                    autoClose: false,
                    disallowClose: true,
                    loading: true,
                    title: "Please Wait",
                });

                setTimeout(() => {
                    updateNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: "Success",
                        message: "The notification will close in 2 seconds, you can close this notification now",
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);

                setTimeout(() => {
                    onHideModal();
                    onUpdate();
                }, 2000);
            })
            .catch(error => {
                const message = error?.response?.data?.message || "An error occurred";
                showNotification({
                    id: "error-notification",
                    disallowClose: false,
                    autoClose: 5000,
                    title: "Error",
                    message: message,
                    color: "red",
                    icon: <IconX className="icon-xmark-circle" color="#fff" size={28} />,
                    className: "my-notification-class",
                    style: { backgroundColor: "white" },
                    sx: { backgroundColor: "white" },
                    loading: false,
                });
            })
            .finally(() => {
                console.log("Final cleanup or actions here");
            });
    };




    useEffect(() => {
        if (modalType === 'edit' && list) {
            form.setFieldsValue({
                code: list.code,
                start: moment(list.start),
                finish: moment(list.finish),
                month: list.month,
                percent: list.percent,
                discount: list.discount_yuzde,
            });
        } else if (modalType === 'add') {
            form.resetFields();
        }
    }, [modalType, list, form]);

    return (
        <>
            {
                modalType === "add" ? (
                    <Form form={form} onFinish={handleSubmit} layout="vertical">
                        <Form.Item
                            label="Code"
                            name="code"
                            rules={[
                                { required: true, message: 'Please enter code!' },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Start Date"
                            name="start"
                            rules={[
                                { required: true, message: 'Please enter start date!' },
                            ]}
                        >
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                            label="End Date"
                            name="finish"
                            rules={[
                                { required: true, message: 'Please enter end date!' },
                            ]}
                        >
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                            label="Month"
                            name="month"
                            rules={[
                                { required: true, message: 'Please select month!' },
                            ]}
                        >
                            <Select placeholder="Select your month">
                                <Option value="1">1</Option>
                                <Option value="6">6</Option>
                                <Option value="12">12</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Percent"
                            name="percent"
                            rules={[
                                { required: true, message: 'Please select percent!' },
                            ]}
                        >
                            <Select placeholder="Select your percent">
                                {[...Array(100).keys()].map(n => (
                                    <Option key={n + 1} value={n + 1}>{n + 1}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Discount Show"
                            name="discount"
                            rules={[
                                { required: true, message: 'Please enter discount!' },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form>

                )
                    :
                    <Form form={form} onFinish={editUpdate} layout="vertical">
                        <Form.Item
                            label="Code"
                            name="code"
                            rules={[{ required: true, message: 'Please enter code!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Start Date"
                            name="start"
                            rules={[{ required: true, message: 'Please enter start date!' }]}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            label="End Date"
                            name="finish"
                            rules={[{ required: true, message: 'Please enter end date!' }]}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            label="Month"
                            name="month"
                            rules={[{ required: true, message: 'Please select month!' }]}
                        >
                            <Select placeholder="Select your month">
                                <Option value="3">3</Option>
                                <Option value="6">6</Option>
                                <Option value="9">9</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Percent"
                            name="percent"
                            rules={[{ required: true, message: 'Please select percent!' }]}
                        >
                            <Select placeholder="Select your percent">
                                {[...Array(100).keys()].map(n => (
                                    <Option key={n + 1} value={n + 1}>{n + 1}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Discount Show"
                            name="discount"
                            rules={[{ required: true, message: 'Please enter discount!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Button type="primary" htmlType="submit">
                            Edit
                        </Button>
                    </Form>
            }
        </>
    );
};

export default DiscountForm;
