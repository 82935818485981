import React, {useContext, createContext, useReducer} from 'react'


const initialState = {
    user: null,
    current_page : "home",
    breadcrumb: [],
    authorization: null,
    menu: null
}

const reducer = (state, action) => {

    /*console.log(state);
    console.log(action);
    console.log(action.payload.user);*/

    switch (action.type) {
        case "SET_USER_DATA":
            return {
                ...state,
                user: action.payload.user,
                menu: action.payload.menu,
            }

        case "SET_CURRENT_PAGE_DATA":
            return {
                ...state,
                breadcrumb: action.payload.breadcrump,
                current_page: action.payload.current_page
            }
        case "SET_AUTHORIZATION":
            return {
                ...state,
                authorization: action.payload.authorization
            }

        default:
            return state
    }
}




const Context = createContext(null)

export const useStore = () => useContext(Context)

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={{ dispatch, state }}>
            {children}
        </Context.Provider>
    )
}