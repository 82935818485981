import React, {useEffect, useState, Fragment} from "react";
import { useNavigate } from 'react-router-dom';
import {useMemo} from 'react';
import { notification } from 'antd';
import axios from 'axios';
import {StoreProvider, useStore} from './store';
import LayoutLogin from "../loginLayout";
import AllRoutes from "../routes";
const queryString = require('query-string');
const baseURL = 'https://qapi.qarint.com/';
var base64js = require('base64-js');


const Auth = ({children}) => {

    const { state, dispatch } = useStore();
    const [localStorageItem, setLocalStorageItem] = useState([]);
    let atoken;
    const navigate = useNavigate();

    useEffect(() => {
        //console.log(state.user);
    },[]);

    if(state.user){
        axios.interceptors.request.use(function (config) {
            atoken = state.user.token;
            config.withCredentials = true;
            config.baseURL = baseURL;
            config.headers.Authorization = `Bearer ${atoken}`;
            config.headers.ContentType = 'application/x-www-form-urlencoded';
            return config;
        }, function (error) {
            notification.open({
                message: 'No Login',
                description:'Login',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
            console.log(error);
            return Promise.reject(error);
        });
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            console.log(error);
            if(error.response.data.message === 'unauthorized'){
                notification.open({
                    message: '! Yetki',
                    description:'Bu sayfadaki veriler için yetkiniz bulunmuyor',
                    onClick: () => {
                       // console.log('Notification Clicked!');
                    },
                });
            }
            return Promise.reject(error);
        });
    }else{

        /*notification.open({
            message: 'No Login',
            description:
                'Login',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });

        navigate(`/`);*/

       const localStorageUser = JSON.parse(localStorage.getItem('user'));
       const localStorageMenu = JSON.parse(localStorage.getItem('menu'));

        if(localStorageUser){

                /*atoken = localStorageUser.token;
                dispatch({
                    type: "SET_USER_DATA",
                    payload: {
                        user: localStorageUser,
                        menu: localStorageMenu,
                    }
                });
                axios.interceptors.request.use(function (config) {
                config.withCredentials = true;
                config.baseURL = baseURL;
                config.headers.Authorization = `Bearer ${atoken}`;
                config.headers.ContentType = 'application/x-www-form-urlencoded';
                return config;
            }, function (error) {
                    notification.open({
                        message: 'No Login',
                        description:'Login',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                console.log(error);
                return Promise.reject(error);
            });*/

        }else{
           /* return (
                <LayoutLogin body={<AllRoutes />}/>
            );*/
        }

        return (
            <LayoutLogin body={<AllRoutes />}/>
        );

    }

    return children;


}

export default Auth;