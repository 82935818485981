import React, { useEffect, useState } from "react";
import {
    Modal,
} from "antd";
import { showNotification,updateNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { IconCheck } from '@tabler/icons-react';
import { Select,Flex,NumberInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import 'dayjs/locale/tr';
import dayjs from 'dayjs';
import Loader from "../atoms/Loader";
import styled from "styled-components";
import CustomButton from "../atoms/Button";
const axios = require("axios").default;

const AddPackage = ({setIsPlanModalVisible,visiblePlanCallback,payment,isPlanModalVisible,getUserList}) => {

    const [loading, setLoading] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [plans, setPlans] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const[subscriptionPlan,setSubscriptionPlan] = useState('')
    const [price, setPrice] = useState("");
    dayjs.locale('tr');
    const handleOk = () => {
        setIsPlanModalVisible(false);
        visiblePlanCallback(null);
    };

    const handleCancel = () => {
       setIsPlanModalVisible(false);
       visiblePlanCallback(null);
    };

    const AddPackages = (subscriptionPlan, startDate,price) => {
        setIsloading(true)
        axios.post(`student/package-add`, {
            user_id:`${payment.user_no}`,
            subscription_plan_id: subscriptionPlan,
            start_date: startDate,
            price:`${price}`
        }).then(function (response) {
                console.log(response);
            }).then(function (response) {
            showNotification({
                id: 'load-data',
                autoClose: false,
                disallowClose: true,
                loading: true,
                title:"Please Wait",
            })
            setTimeout(() => {
                updateNotification({
                    id: 'load-data',
                    color: 'teal',
                    title:"Success",
                    message:"The notification will close in 2 seconds, you can close this notification now",
                    icon: <IconCheck size="1rem" />,
                    autoClose: 1000,
                });
            }, 1000);
            setTimeout(() => {
                setIsPlanModalVisible(false);
                getUserList()
            }, 2000);
        }).catch(function (error) {
            const message = error?.response?.data?.message;
            showNotification({
                id: "hello-there",
                disallowClose: false,
                autoClose: 5000,
                title: message,
                message:"The notification will close in 2 seconds, you can close this notification now",
                color: "red",
                icon: (
                    <IconX
                        className={"icon-xmark-circle"}
                        iconColor={"#fff"}
                        size={28}
                    />
                ),
                className: "my-notification-class",
                style: { backgroundColor: "white" },
                sx: { backgroundColor: "white" },
                loading: false,
            });
          }).finally(() => {
            setIsloading(false)

        })

    };



    const getPlans = () => {
        setLoading(true);
        axios.get(`student/packets`)
            .then(function (response) {
                setPlans(response?.data?.data);
                setLoading(false);
                return response;
            })
            .catch(function (error) {
              console.log(error)
            });
    };


    useEffect(() => {
        if (payment?.length !== 0) {
            getPlans();
            setSelectedDate("")
            setPrice("")
        }
    }, [isPlanModalVisible]);


    const formatDataForSelect = (plans) => {
        return plans?.map(item => ({
            value: item?.id?.toString(),
            label: `${item?.monthly} ay ${item?.numberLesson} kez`
        }));
    };
    const options = formatDataForSelect(plans);


    const handleUserChange = (value) => {
        setSubscriptionPlan(value)
    };
    const handleDateChange = (date) => {
        setSelectedDate(date ? dayjs(date) : null);
    };

    const handleSaveClick = () => {
        if (!subscriptionPlan) {
            showNotification({
                id: 'hello-there',
                disallowClose: true,
                autoClose: 5000,
                title:"Please Make Your Choice",
                message:"The notification will close in 2 seconds, you can close this notification now",
                color: 'red',
                icon: <IconX size="1rem" />,
                className: 'my-notification-class',
                style: { backgroundColor: 'white' },
                sx: { backgroundColor: 'white' },
                loading: false,
            });
        }
        if (!price) {
            showNotification({
                id: 'hello-there',
                disallowClose: true,
                autoClose: 5000,
                title:"Please Enter Price Information",
                message:"The notification will close in 2 seconds, you can close this notification now",
                color: 'red',
                icon: <IconX size="1rem" />,
                className: 'my-notification-class',
                style: { backgroundColor: 'white' },
                sx: { backgroundColor: 'white' },
                loading: false,
            });
        }
        if (!selectedDate) {
            showNotification({
                id: 'hello-there',
                disallowClose: false,
                autoClose: 5000,
                title:"Please Select Date.",
                message:"The notification will close in 2 seconds, you can close this notification now",
                color: 'red',
                icon: <IconX size="1rem" />,
                className: 'my-notification-class',
                style: { backgroundColor: 'white' },
                sx: { backgroundColor: 'white' },
                loading: false,
            });
        }

        const formattedDate = selectedDate.format('YYYY-MM-DD HH:mm');
        AddPackages(subscriptionPlan, formattedDate,price);
    };



    return (
        <>
            <ModalX
                title={`Add Subscription (${payment?.first_name + " " + payment?.last_name})`}
                visible={isPlanModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={700}
                confirmLoading={true}
            >
                {loading ? (
                    <StyledLoad>
                        <Loader size={"sm"} />
                    </StyledLoad>
                ) : (
                    <>
                        {(
                            <Flex
                               id={"payment-wrap-list"}
                               display={"flex"}
                               direction={"column"}
                               gap={"30px"}
                            >
                               <Flex
                                display={"flex"}
                                direction={"column"}
                                gap={"30px"}
                               >
                                   <Select
                                       label="Subscription Packages"
                                       placeholder="Pick one"
                                       searchable
                                       nothingFound="No options"
                                       data={options}
                                       onChange={handleUserChange}
                                   />

                                   <NumberInput
                                       decimalSeparator=","
                                       thousandsSeparator="."
                                       placeholder={"Pick one"}
                                       label="Price"
                                       value={price}
                                       onChange={setPrice}
                                       precision={3}
                                       step={0.5}
                                   />

                                   <div>
                                       <DateInput
                                           valueFormat="DD-MM-YYYY"
                                           value={selectedDate}
                                           label="Start Date"
                                           placeholder="Pick one"
                                           mx="auto"
                                           clearable={true}
                                           onChange={handleDateChange}
                                           locale={"tr"}
                                       />
                                   </div>
                               </Flex>
                            <Flex
                                display={"flex"}
                                justify={"right"}
                            >
                                <CustomButton
                                    text={"Save"}
                                    loading={isLoading}
                                    onClick={() => handleSaveClick()}
                                />
                            </Flex>
                          </Flex>
                        )}
                    </>
                )}
            </ModalX>
        </>
    );
};



const StyledLoad = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`
const ModalX = styled(Modal)`
  .ant-modal-content{
    min-height: 300px;
  }
`

export default AddPackage;
