import React, {useState, useEffect} from "react";
import {Button, Modal} from "antd";
import DiscountForm from "./form";

const CustomModal = ({showModal, setShowModal, setEditModal, editModal, list, onUpdate}) => {
    const [modalType, setModalType] = useState('');
    const onHideModal = () => {
        setShowModal(false);
    };

    const openAddModal = () => {
        setModalType('add');
        setShowModal(true);
    };

    useEffect(() => {
        if (editModal) {
            setModalType('edit');
            setShowModal(true);
            setEditModal(false);
        }
    }, [editModal, setEditModal]);


    return (
        <>
            <Button style={{backgroundColor: "#9e0c48", border: "none", color: "#fff"}} onClick={openAddModal}>
                Add Discount
            </Button>


            <Modal
                title={modalType === 'edit' ? "Edit Discount" : "Add Discount"}
                centered
                visible={showModal}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                footer={false}
                closable={true}
            >
                <DiscountForm
                    onHideModal={onHideModal}
                    editModal={editModal}
                    setEditModal={setEditModal}
                    modalType={modalType}
                    list={list}
                    onUpdate={onUpdate}
                />
            </Modal>
        </>
    );
};

export default CustomModal;
